import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ResourceType } from '../model/resourceType.model';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable()
export class ResourceTypeService {
  resourceTypes$: Subject<ResourceType[]>;

  constructor(private apiService: ApiService) {
    this.resourceTypes$ = new Subject<ResourceType[]>();
  }

  async setResourceTypes() {
    const resourceTypes = await this.apiService.get('resource-types');
    this.resourceTypes$.next(convertKeys.toCamel(resourceTypes));
  }
}
