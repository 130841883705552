<div class="app-portfolio-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>edit</mat-icon>
                </div>
                <h1 class="ellipsis" data-automation="edit-title">{{ 'portfolio.edit.title' | translate }}</h1>
                <p class="desktop-only subtitle" data-automation="edit-subtitle">
                    {{ 'portfolio.edit.subtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                color="gray"
                (click)="handleCancel()"
                [disabled]="this.portfolioId === ''"
                data-automation="portfolioCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'portfolio.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                data-automation="delete"
                [disabled]="this.portfolioId === ''"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'portfolio.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                (click)="handleSubmit()"
                data-automation="portfolioSubmit"
                [disabled]="!portfolioForm.valid || !editForm.valid"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'portfolio.button.save' | translate }}</span>
            </button>
        </div>
    </header>
    <div class="page-content">
        <form #editForm="ngForm" class="portfolio-form">
            <app-portfolio-form
                [mode]="mode"
                [portfolioForm]="portfolioForm"
                [missingRegs]="missingRegs"
            ></app-portfolio-form>
        </form>
    </div>
</div>
