<div
    id="telemetryPointsForm"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
    ngModelGroup="telemetryPointsForm_{{ index }}"
    data-automation="pointContainer"
>
    <mat-expansion-panel
        (opened)="expandPanel()"
        (closed)="panelOpenedState = false"
        togglePosition="before"
        class="expansion-panel panel_{{ index }}"
        #telemetryPanel
    >
        <mat-expansion-panel-header
            class="panel-override"
            collapsedHeight="74px"
            expandedHeight="48px"
            data-automation="pointHeader"
        >
            <mat-panel-title id="panel-{{index}}" class="point-title point-title_{{index}}">
                <div data-automation="displayLabel">
                    <ng-container>
                        {{ displayLabel }}
                    </ng-container>
                </div>
                <div>
                    <i *ngIf="isViewMode" (click)="handleEdit($event)"
                        ><img
                            class="edit-icon"
                            id="edit_panel_{{ index }}"
                            src="../../../assets/svg/edit-new.svg"
                            alt=""
                    /></i>
                    <i *ngIf="isCreateMode || isEditMode" (click)="deletePoint($event, index)"
                        ><img
                            class="edit-icon delete_panel_{{ index }}"
                            data-index="{{ index }}"
                            id="delete_panel_{{ index }}"
                            src="../../../assets/svg/delete.svg"
                            alt=""
                    /></i>
                </div>
            </mat-panel-title>

            <mat-panel-description class="point-subtitle">
                {{ panelOpenedState ? '' : pointSourceId }}
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div id="panelFields-{{index}}" class="panel-override" data-automation="telemetryFields">
            <div class="form-entry" *ngIf="isEditMode || isCreateMode">
                <multi-locale-input
                    name="displayLabels"
                    [inputLabel]="nameLabel"
                    [locales]="localeConfig.supportedLocales"
                    [(ngModel)]="point.displayLabels"
                    [displayLabelKey]="localeConfig.displayLabelKey"
                    [localeKey]="localeConfig.localeKey"
                    [defaultLocale]="localeConfig.defaultLocale"
                    [placeholderText]="namePlaceholder"
                    [required]="true"
                    [id]="getDisplayLabelsId()"
                    [maxLength]="500"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry" *ngIf="isCreateMode || isEditMode">
                <multi-locale-input
                    name="descriptions"
                    type="text"
                    [inputLabel]="descriptionLabel"
                    [locales]="localeConfig.supportedLocales"
                    [(ngModel)]="point.descriptions"
                    [displayLabelKey]="localeConfig.displayLabelKey"
                    [localeKey]="localeConfig.localeKey"
                    [defaultLocale]="localeConfig.defaultLocale"
                    [placeholderText]="descriptionPlaceholder"
                    [id]="getDescriptionLabelsId()"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry" *ngIf="isViewMode">
                <mat-label class="info-title">{{ 'telemetry_point.create.description' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="description" *ngIf="point.description">
                    {{ point.description }}
                </mat-label>
                <mat-label class="info-value info-placeHolder" data-automation="description" *ngIf="!point.description">
                    {{ 'telemetry_point.create.placeholder.description' | translate }}
                </mat-label>
            </div>

            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'telemetry_point.create.data_provider' | translate }}</mat-label>
                    <mat-select
                        id="dataProvider_{{index}}"
                        name="dataProvider_{{index}}"
                        #dataProvider="ngModel"
                        class="create-select"
                        [ngClass]="{ failure: dataProvider.invalid && (dataProvider.dirty || dataProvider.touched) }"
                        [(ngModel)]="point.dataProvider"
                        data-automation="dataProviderSelector"
                        appSelectValid
                        [class.loading-field]="loadingDataProviders"
                        required
                        (selectionChange)="handleDataProviderChange($event)"
                        placeholder="{{
                            isEditMode
                                ? getSelectedDataProviderLabel()
                                : ('telemetry_point.create.placeholder.data_provider' | translate)
                        }}"
                    >
                        <mat-option
                            *ngFor="let provider of dataProviders"
                            [value]="provider.id"
                            id="dataProviderOpt_{{ provider.id }}"
                            >{{ provider.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error
                        *ngIf="dataProvider.invalid && (dataProvider.dirty || dataProvider.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="dataProvider.errors.selectValid">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'portfolio.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingDataProviders" align="end">{{
                        'portfolio.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="loadingDataProviders" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'telemetry_point.create.data_provider' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="dataProvider">
                        {{ getSelectedDataProviderLabel() }}
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'telemetry_point.create.channel' | translate }}</mat-label>
                    <mat-select
                        id="channel_{{ index }}"
                        name="channel_{{ index }}"
                        #channel="ngModel"
                        class="create-select"
                        [ngClass]="{ failure: channel.invalid && (channel.dirty || channel.touched) }"
                        [(ngModel)]="point.channelId"
                        data-automation="channelSelector"
                        (selectionChange)="onChannelChange($event)"
                        [class.loading-field]="loadingChannels"
                        appSelectValid
                        [compareWith]="channelCompare"
                        placeholder="{{ 'telemetry_point.create.placeholder.channel' | translate }}"
                        required
                    >
                        <mat-option
                            *ngFor="let channel of channels"
                            [value]="channel.id"
                            id="channel_{{ channel.id }}"
                            [disabled]="checkExistingPointChannels(channel.id)"
                            >{{ channel.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="channel.invalid && (channel.dirty || channel.touched)" class="alert alert-danger">
                        <mat-error *ngIf="channel.errors.selectValid">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'portfolio.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingChannels" align="end">{{ 'portfolio.loading' | translate }}</mat-hint>
                    <mat-spinner *ngIf="loadingChannels" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'telemetry_point.create.channel' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="channel">
                        {{ pointChannel }}
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'telemetry_point.create.reporting_interval' | translate }}</mat-label>
                    <mat-select
                        id="reportingInterval_{{ index }}"
                        name="reportingInterval_{{ index }}"
                        #reportingInterval="ngModel"
                        class="create-select"
                        [ngClass]="{
                            failure: reportingInterval.invalid && (reportingInterval.dirty || reportingInterval.touched)
                        }"
                        [(ngModel)]="point.reportingInterval"
                        data-automation="reportingIntervalSelector"
                        appSelectValid
                        [class.loading-field]="loadingReportingIntervals"
                        required
                        [compareWith]="reportingIntervalCompare"
                        placeholder="{{ 'telemetry_point.create.placeholder.reporting_interval' | translate }}"
                    >
                        <mat-option
                            *ngFor="let interval of reportingIntervals"
                            [value]="interval.durationInMilliseconds"
                            id="interval_{{ interval.id }}"
                            >{{ interval.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error
                        *ngIf="reportingInterval.invalid && (reportingInterval.dirty || reportingInterval.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="reportingInterval.errors.selectValid">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'portfolio.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="loadingReportingIntervals" align="end">{{
                        'portfolio.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="loadingReportingIntervals" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title"
                        >{{ 'telemetry_point.create.reporting_interval' | translate }}:</mat-label
                    >
                    <mat-label class="timezone-value info-value" data-automation="reportingInterval">
                        {{ getPointReportingInterval() }}
                    </mat-label>
                </ng-container>
            </div>

            <div class="form-entry" id="sourceId-{{index}}">
                <mat-form-field *ngIf="isCreateMode || isEditMode" appearance="outline" floatLabel="always">
                    <mat-label>{{ 'telemetry_point.create.source_id' | translate }}</mat-label>
                    <input
                        matInput
                        id="sourceId_{{ index }}"
                        #sourceId="ngModel"
                        type="text"
                        [ngClass]="{ failure: sourceId.invalid && (sourceId.dirty || sourceId.touched) }"
                        name="sourceId_{{ index }}"
                        placeholder="{{ 'telemetry_point.create.placeholder.source_id' | translate }}"
                        minlength="6"
                        maxlength="80"
                        pattern="[a-zA-Z0-9-_\s]+"
                        [(ngModel)]="point.sourceId"
                        (blur)="checkSourceId($event)"
                        data-automation="sourceId"
                        data-url-persist
                        required
                        appSourceValid
                    />
                    <mat-error
                        *ngIf="sourceId.invalid && (sourceId.dirty || sourceId.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="sourceId.errors.required">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'portfolio.validation.required' | translate }}
                            </span>
                        </mat-error>
                        <mat-error *ngIf="sourceId.errors.pattern">
                            <span class="input-title"></span>
                            <span data-automation="patternError">{{
                                'portfolio.validation.pattern' | translate
                            }}</span>
                        </mat-error>
                        <mat-error *ngIf="sourceId.errors.sourceInvalid">
                            <span class="input-title"></span>
                            <span data-automation="sourceIDInvalid">
                                {{ 'portfolio.validation.sourceInvalid' | translate }}
                            </span>
                        </mat-error>
                        <mat-error *ngIf="sourceId.errors.minlength">
                            <span class="input-title"></span>
                            <span data-automation="lengthError">
                                {{ 'portfolio.validation.minLength' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
                <ng-container *ngIf="isViewMode">
                    <mat-label class="info-title">{{ 'telemetry_point.create.source_id' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="sourceId">
                        {{ pointSourceId }}
                    </mat-label>
                </ng-container>
            </div>

            <div *ngIf="isCreateMode || isEditMode" class="form-entry form-entry-column">
                <div class="checkbox-wrapper">
                    <mat-checkbox
                        disabled="true"
                        id="veeBypass_{{ index }}"
                        #veeBypass="ngModel"
                        [ngClass]="{ failure: veeBypass.invalid && (veeBypass.dirty || veeBypass.touched) }"
                        name="veeBypass_{{ index }}"
                        [(ngModel)]="point.veeBypass"
                        data-automation="veeBypass"
                        data-url-persist
                        >{{ 'telemetry_point.create.set_vee_bypass' | translate }}</mat-checkbox
                    >
                    <mat-error
                        *ngIf="veeBypass.invalid && (veeBypass.dirty || veeBypass.touched)"
                        class="alert alert-danger"
                    >
                        <mat-error *ngIf="veeBypass.errors.required">
                            <span class="input-title"></span>
                            <span data-automation="alertRequired">
                                {{ 'portfolio.validation.required' | translate }}
                            </span>
                        </mat-error>
                    </mat-error>
                </div>
            </div>

            <div *ngIf="isViewMode" class="form-entry">
                <ng-container>
                    <mat-label class="info-title">{{ 'telemetry_point.create.set_vee_bypass' | translate }}:</mat-label>
                    <mat-label class="info-value" data-automation="veeBypass">
                        {{ point.veeBypass ? ('telemetry_point.shared.true' | translate) : ('telemetry_point.shared.false' | translate) }}
                    </mat-label>
                </ng-container>
            </div>
        </div>
    </mat-expansion-panel>
</div>
