import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { PortfolioService } from '../shared/services/portfolios.service';
import { NgxDeeplinkerService } from 'ngx-deeplinker';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Portfolio } from '../shared/model/portfolio';
import { Locale } from '../shared/model/locale.model';
import { ValidatorFunctions } from '../shared/validators/validator.functions';
import * as moment from 'moment-timezone';
import { PortfolioFormComponent } from '../portfolio-form/portfolio-form.component';
import { TelemetryPointService } from '../shared/services/telemetry-point.service';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss', '../shared/shared.styles.scss'],
})
export class EditComponent implements OnInit, OnDestroy {
  SUCCESS = 'Created Successfully';
  BAD_REQUEST = 'Oops, There was a problem with your request';
  NOT_CREATED = 'Oops, There was a problem creating your asset';
  REQUIRED = 'required';
  CONFLICT = 'There is a data conflict with this Portfolio';
  POINTS_CREATED = 'Successfully updated';
  POINTS_FAILED = 'Failed to update';
  TELEMETRY_POINTS = 'Telemetry Points';
  FAILED_REGISTRATIONS = 'Not all Registrations could be assigned';
  ALL_FAILED_REGISTRATIONS = 'None of the registrations qualified to be assigned to the portfolio.';

  portfolioId = '';
  subscriptions = [];
  isSubmitting = false;
  isLoadingAsset = false;

  currentSite = null;
  portfolioForm: UntypedFormGroup = new UntypedFormGroup({
    id: new UntypedFormControl('', Validators.required),
    descriptions: new UntypedFormControl({}),
    displayLabels: new UntypedFormControl({}, Validators.required),
    supportedLocales: new UntypedFormControl([new Locale()]),
    defaultLocale: new UntypedFormControl('en_US', Validators.required),
    timezone: new UntypedFormControl('America/New_York', Validators.required),
    registrations: new UntypedFormControl([]),
    programId: new UntypedFormControl(''),
    externalReferenceId: new UntypedFormControl('', Validators.required),
    effectiveFrom: new UntypedFormControl(new Date().getUTCDate(), Validators.required),
    effectiveTo: new UntypedFormControl(
      moment(new Date())
        .add(1, 'days')
        .toDate(),
      ValidatorFunctions.dateAfterBuilder('effectiveFrom'),
    ),
    marketConf: new UntypedFormGroup({
      reserveRate: new UntypedFormControl(''),
      rampRate: new UntypedFormControl(''),
      resourceType: new UntypedFormControl(''),
    }),
  });
  readonly mode = 'edit';
  readonly APPPREFIX = 'prt';
  private portfolio: Portfolio;
  private portfolioComponentView: PortfolioFormComponent;
  missingRegs;

  @ViewChild(PortfolioFormComponent, { static: false })
  set portfolioComponent(portfolioDetailsComponent: PortfolioFormComponent) {
    this.portfolioComponentView = portfolioDetailsComponent;
  }

  get portfolioComponent() {
    return this.portfolioComponentView;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private messageService: GlobalAlertService,
    private portfolioService: PortfolioService,
    private ngxDeeplinkerService: NgxDeeplinkerService,
    private translateService: TranslateService,
    private telemetryPointService: TelemetryPointService,
    public dialog: MatDialog,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.translateService.get('portfolio.notification.updated_successfully').subscribe((result: string) => {
      this.SUCCESS = result;
      this.BAD_REQUEST = this.translateService.instant('portfolio.notification.bad_request');
      this.NOT_CREATED = this.translateService.instant('portfolio.notification.not_created');
      this.REQUIRED = this.translateService.instant('portfolio.validation.required');
      this.CONFLICT = this.translateService.instant('portfolio.notification.conflict');
      this.POINTS_CREATED = this.translateService.instant('telemetry_point.edit.success');
      this.POINTS_FAILED = this.translateService.instant('telemetry_point.edit.failed');
      this.TELEMETRY_POINTS = this.translateService.instant('telemetry_point.edit.points');
      this.FAILED_REGISTRATIONS = this.translateService.instant('portfolio.failed_reg');
      this.ALL_FAILED_REGISTRATIONS = this.translateService.instant('portfolio.failed_reg_all');
    });

    const errSub = this.portfolioService.conflictError.subscribe(() => {
      this.messageService.setError(this.CONFLICT, 7000);
    });

    this.subscriptions.push(...[errSub]);
  }

  ngOnInit() {
    this.handleMissingRegs();
    const routeSub = this.route.params.subscribe(async params => {
      console.log(params);
      if (params.id) {
        this.portfolioId = params.id;
        this.isLoadingAsset = true;
        this.portfolioService.selectedPortfolioId$.next(this.portfolioId);
        const portfolio = await this.portfolioService.getPortfolio(params.id);
        if (this.portfolioService.selectedProgram$.value.id !== portfolio.programId) {
          this.portfolioService.selectProgram(portfolio.programId);
        }
        this.isLoadingAsset = false;
      }
    });

    const portfolioSub = this.portfolioService.portfolio$.subscribe(async portfolio => {
      console.log('GET Portfolio', portfolio);
      if (portfolio) {
        this.portfolio = portfolio;
        this.portfolioForm.patchValue(portfolio);
      }
    });
    this.subscriptions.push(...[routeSub, portfolioSub]);
  }

  handleMissingRegs() {
    const missingRegs = this.route.snapshot.queryParamMap.get('missingRegs');
    if (missingRegs !== null) {
      this.missingRegs = JSON.parse(missingRegs);
      console.log(this.missingRegs);
    }
  }

  ngAfterViewInit() {
    if (this.telemetryPointService.hasBrokenPoints) {
      this.messageService.setError(this.POINTS_FAILED);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private async handleEdit(id: string, missingRegs?: string[]) {
    this.isSubmitting = false;
    this.portfolioService.refetchPortfolios();
    let queryParams: any = {};
    if (missingRegs) {
      queryParams.missingRegs = JSON.stringify(missingRegs);
    }
    this.router.navigate([`details/${id}/edit`], { queryParams });
  }

  async handleSubmit() {
    console.log('PUT', this.portfolioForm.getRawValue());
    if (!this.portfolioForm.valid) {
      this.messageService.setError(this.REQUIRED);
    } else {
      try {
        this.isSubmitting = true;
        const { points } = this.portfolioComponentView;
        const newPortfolio: Portfolio = this.portfolioForm.getRawValue();
        newPortfolio.alternateIds = this.portfolio.alternateIds;
        const response = await this.portfolioService.updatePortfolio(newPortfolio);

        if (points) {
          await this.telemetryPointService.createOrUpdatePoints(this.portfolioId, points);
        }
        this.ngxDeeplinkerService.returnHandler({ appPrefix: this.APPPREFIX, callbackValue: response.id });
        if (this.telemetryPointService.hasBrokenPoints) {
          this.messageService.setError(this.POINTS_FAILED);
          this.isSubmitting = false;
        } else {
          this.messageService.setSuccess(this.SUCCESS);
          setTimeout(() => {
            this.portfolioService.refetchPortfolios();
            this.router.navigate([`details/${response.id}/view`], {});
          }, 2000);
        }
      } catch (e) {
        console.log(e);
        if (e.error && e.error.code === 400 && e.error.detail) {
          this.messageService.setError(this.ALL_FAILED_REGISTRATIONS);
        } else if (e.code === 206) {
          this.messageService.setError(this.FAILED_REGISTRATIONS);
          this.handleEdit(e.detail.data.id, e.detail.missingRegs);
        } else {
          let errorMessage = '';
          if (e.error && e.error.message) {
            errorMessage = e.error.message;
          } else {
            errorMessage = 'ERR_BAD_REQUEST';
          }
          if (errorMessage === 'ERR_BAD_REQUEST') {
            this.messageService.setError(this.BAD_REQUEST);
          } else {
            this.messageService.setError(this.NOT_CREATED);
          }
        }
      }
      this.isSubmitting = false;
    }
  }

  openDeleteDialog(): void {
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        portfolioId: this.portfolioId,
      },
    });
  }

  handleCancel() {
    this.router.navigate([`details/${this.portfolioId}/view`]);
  }
}
