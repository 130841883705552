<div class="content">
    <div class="page-content">
        <ng-container *ngIf="noPortfolios">
            <div class="empty-page">
                <mat-icon>warning</mat-icon>
                <h2>{{ 'portfolio.list.none_configured' | translate }}</h2>
            </div>
        </ng-container>

        <ng-container *ngIf="loading">
            <div class="empty-page">
                <mat-spinner diameter="60"></mat-spinner>
                <h2>{{ 'portfolio.loading' | translate }}...</h2>
            </div>
        </ng-container>
    </div>
</div>
