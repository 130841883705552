import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Space } from '../model/space.model';
import { BehaviorSubject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';

@Injectable()
export class SelectedSpaceService {
  private subject: ReplaySubject<Space> = new ReplaySubject(1);
  sites$: BehaviorSubject<Space[]>;


  constructor(private apiService: ApiService) {
    this.sites$ = new BehaviorSubject<Space[]>([]);
  }

  get space(): ReplaySubject<Space> {
    return this.subject;
  }

  async setOrgSites(orgId: string) {
    try {
      const sites = await this.apiService.get(`organizations/${orgId}/sites`);
      this.sites$.next(convertKeys.toCamel(sites));
    } catch (err) {
      console.log('Could not load sites.', err);
    }
  }
}
