import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { Portfolio } from '../model/portfolio';
import { Program } from '../model/program';
import { Registration } from '../model/registration';

@Injectable()
export class PortfolioService {
  conflictError = new Subject<any[]>();
  readonly portfolios$ = new BehaviorSubject<Portfolio[]>(null);
  readonly portfolio$ = new BehaviorSubject<Portfolio>(null);
  readonly programs$ = new BehaviorSubject<Program[]>([]);
  readonly registrations$ = new BehaviorSubject<Registration[]>([]);
  readonly selectedPortfolioId$ = new BehaviorSubject<string>(null);
  readonly selectedProgram$ = new BehaviorSubject<Program>(null);

  constructor(private apiService: ApiService) {}

  async getPrograms() {
    try {
      const programs = await this.apiService.get(`programs`);
      this.programs$.next(convertKeys.toCamel(programs));
    } catch (err) {
      console.log('Could not load programs.', err);
    }
  }

  async getPortfolio(id: string) {
    let rawPortfolio: any = {};

    try {
      let response = await this.apiService.get(`portfolios/${id}`);
      if (response.status === 409) {
        const { data } = response;
        this.conflictError.next(null);
        rawPortfolio = data.portfolio;
      } else {
        rawPortfolio = response;
      }

      const { PORTFOLIO_EXTERNAL_REFERENCE_ID: external_reference_id = '' } = rawPortfolio?.alternate_ids || {};

      rawPortfolio.external_reference_id = external_reference_id;

      if (!rawPortfolio?.market_conf) {
        rawPortfolio.market_conf = {};
      }

      const portfolio: any = convertKeys.toCamel(rawPortfolio);
      portfolio.displayLabels = rawPortfolio.display_labels;
      portfolio.descriptions = rawPortfolio.descriptions;
      this.portfolio$.next(portfolio);
      this.selectedPortfolioId$.next(id);
      return portfolio;
    } catch (err) {
      console.log('Could not load portfolio.', err);
      throw err;
    }
  }

  selectProgram(programId: string) {
    if (this.programs$.value && this.programs$.value.length > 0) {
      const program = this.programs$.value.find(program => {
        return program.id === programId;
      });
      this.selectedProgram$.next(program);
    }
  }

  async getPortfoliosByProgram(programId: string) {
    try {
      const portfolios = await this.apiService.get(`programs/${programId}/portfolios`);
      this.portfolios$.next(convertKeys.toCamel(portfolios));
      await this.getRegistrationsByProgram(programId);
    } catch (err) {
      console.log(`Could not load Portfolios for Program: ${programId}`, err);
      this.portfolios$.next([]);
    }
  }

  async getRegistrationsByProgram(programId: string) {
    try {
      const registrations = await this.apiService.get(`programs/${programId}/registrations`);
      this.registrations$.next(convertKeys.toCamel(registrations));
    } catch (err) {
      console.log(`Could not load Registrations for Program: ${programId}`, err);
      this.registrations$.next([]);
    }
  }

  async refetchPortfolios() {
    if (this.selectedProgram$.value) {
      await this.getPortfoliosByProgram(this.selectedProgram$.value.id);
    }
  }

  async createPortfolio(portfolio: Portfolio) {
    try {
      if (portfolio.marketConf) {
        Object.keys(portfolio.marketConf).forEach(key => {
          if (portfolio.marketConf[key] === null || portfolio.marketConf[key] === '') {
            delete portfolio.marketConf[key];
          }
        });
      }
      const dto = convertKeys.toSnake<any>(portfolio);
      dto.display_labels = portfolio.displayLabels;
      dto.descriptions = portfolio.descriptions;
      const createdPortfolio = await this.apiService.post('portfolios', dto);
      return convertKeys.toCamel<Portfolio>(createdPortfolio);
    } catch (err) {
      console.log(`Could not Create Portfolio`, err);
      throw err;
    }
  }

  async deletePortfolio(portfolioId: string) {
    const deletedPortfolio = await this.apiService.delete(`portfolios/${portfolioId}`);
    return convertKeys.toCamel<any>(deletedPortfolio);
  }

  async updatePortfolio(portfolio: Portfolio) {
    try {
      const id = portfolio.id;
      if (portfolio.marketConf) {
        Object.keys(portfolio.marketConf).forEach(key => {
          if (portfolio.marketConf[key] === null || portfolio.marketConf[key] === '') {
            delete portfolio.marketConf[key];
          }
        });
      }
      const dto = convertKeys.toSnake<any>(portfolio);
      dto.display_labels = portfolio.displayLabels;
      dto.descriptions = portfolio.descriptions;
      const updatedPortfolio = await this.apiService.put(`portfolios/${id}`, dto);
      return convertKeys.toCamel<any>(updatedPortfolio);
    } catch (err) {
      console.log(`Could not Update Portfolio`, err);
      throw err;
    }
  }
}
