export class TelemetryPoint {
    public id?: string;
    public displayLabels: any;
    public channelId: string;
    public dataProvider: string;
    public defaultLocale?: string;
    public description?: string;
    public descriptions: any;
    public reportingInterval: string;
    public portfolioId: string;
    public sourceId: string;
    public timezone?: string;
    public veeBypass: boolean;
    public deleted?: boolean;
    public toEdit?: boolean;
  
    constructor(payload) {
      this.id = payload.id;
      this.displayLabels = payload.display_labels;
      this.channelId = payload.channel_id;
      this.dataProvider = payload.dataprovider_id ? payload.dataprovider_id : payload.data_provider;
      this.defaultLocale = payload.default_locale;
      this.descriptions = payload.descriptions;
      this.description = this.descriptions ? this.descriptions[this.defaultLocale] : '';
      this.reportingInterval = payload.reporting_interval_ms || payload.reporting_interval;
      this.portfolioId = payload.portfolio_id;
      this.sourceId = payload.alternate_ids ? payload.alternate_ids['SOURCE_ID'] : payload.source_id;
      this.timezone = payload.timezone;
      this.veeBypass = payload.vee_bypass;
    }
  
    toPayload() {
      return {
        display_labels: this.displayLabels,
        channel_id: String(this.channelId),
        data_provider: this.dataProvider,
        default_locale: this.defaultLocale,
        descriptions: this.descriptions,
        reporting_interval: String(this.reportingInterval),
        portfolio_id: this.portfolioId,
        source_id: this.sourceId,
        timezone: this.timezone,
        vee_bypass: this.veeBypass,
      };
    }
  }
  