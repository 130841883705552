import { Component, OnDestroy, OnInit } from '@angular/core';
import { PortfolioService } from '../shared/services/portfolios.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss', '../shared/shared.styles.scss'],
})
export class DefaultComponent implements OnInit, OnDestroy {
  loading = true;
  noPortfolios = false;
  portfolios;
  subscriptions: Subscription[] = [];

  constructor(private router: Router, private portfolioService: PortfolioService) {
    this.portfolioService.selectedPortfolioId$.next(null);
  }

  navigateToFirstPortfolio(portfolios) {
    if (portfolios && portfolios.length > 0) {
      this.loading = true;
      this.noPortfolios = false;
      this.router.navigate([`details/${portfolios[0].id}/view`], {});
    } else {
      this.loading = false;
      this.noPortfolios = true;
    }
  }

  async ngOnInit() {
    if (this.portfolioService.selectedProgram$.value) {
      // refresh asset list
      await this.portfolioService.refetchPortfolios();
    }
    const portSub = this.portfolioService.portfolios$.subscribe(portfolios => {
      if (this.router.isActive('/', true)) {
        this.portfolios = portfolios;
        this.navigateToFirstPortfolio(portfolios);
      }
    });
    this.subscriptions.push(portSub);
  }

  ngOnDestroy() {
    for (let sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }
}
