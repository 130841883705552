import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { TelemetryPoint } from '../model/telemetry-point.model';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class TelemetryPointService {
  redirectUrl: string;
  readonly brokenPoints$: BehaviorSubject<TelemetryPoint[]> = new BehaviorSubject<TelemetryPoint[]>([]);
  private brokenPoints: TelemetryPoint[] = [];

  constructor(private apiService: ApiService) {
    this.apiService = apiService;
  }

  get hasBrokenPoints() {
    return Boolean(this.brokenPoints$.getValue().length);
  }

  addBrokenPoint(point: TelemetryPoint) {
    this.brokenPoints.push(point);
  }

  clearBrokenPoints() {
    this.brokenPoints$.next([]);
    this.brokenPoints = [];
  }

  pushBrokenPoints() {
    this.brokenPoints$.next(this.brokenPoints);
    this.brokenPoints = [];
  }

  async createPoint(portfolioId: string, point: TelemetryPoint) {
    let resp;
    point.defaultLocale = point.defaultLocale;
    point.timezone = point.timezone;
    try {
      resp = await this.apiService.post(`portfolios/${portfolioId}/telemetry-points`, point.toPayload());
      return resp;
    } catch (e) {
      point.toEdit = true;
      this.addBrokenPoint(point);
    }
  }

  async updatePoint(point: TelemetryPoint) {
    try {
      const id = point.id;
      const dto = point.toPayload();
      return await this.apiService.put(`telemetry-points/${id}`, dto);
    } catch (error) {
      point.toEdit = true;
      this.addBrokenPoint(point);
    }
  }

  async deletePoint(id: string) {
    return await this.apiService.delete(`telemetry-points/${id}`);
  }

  async getPointsForPortfolio(id: string) {
    try {
      const resp = await this.apiService.get(`portfolios/${id}/telemetry-points`);
      return resp.map(point => new TelemetryPoint(point));
    } catch (error) {
      console.log("Error fetching telemetry points", error);
    }
  }

  async createOrUpdatePoints(portfolioId: string, points: TelemetryPoint[]) {
    this.clearBrokenPoints();
    let data = [];
    for (const point of points) {
      let saved;
      if (point.deleted) {
        if (point.id) {
          await this.deletePoint(point.id);
        }
      } else if (point.id) {
        saved = await this.updatePoint(point);
        data.push(saved);
      } else {
        saved = await this.createPoint(portfolioId, point);
        data.push(saved);
      }
    }
    if(this.brokenPoints.length) {
      this.brokenPoints = [...this.brokenPoints, ...await this.getPointsForPortfolio(portfolioId)];
    }
    this.pushBrokenPoints();
    return data;
  }
}
