import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, GlobalNavService, Context } from 'ngx-global-nav';
import { AnalyticsService, CookieService as NgxCookieService } from 'ngx-shared-services';
import { NavService } from './shared/services/global-nav.service';
import { environment } from 'src/environments/environment.prod';
import { ApiEndpointsService } from './services/api-endpoints.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './shared/services/sidenav.service';
import { PortfolioService } from './shared/services/portfolios.service';
import { Router } from '@angular/router';
import { OrganizationsService } from './shared/services/organizations.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  sidenavOpen = true;
  navUrl = '';
  programs: any[];
  selectedProgramId: string;
  isInit = true;
  orgList: Context[] = [];

  constructor(
    private router: Router,
    private translate: TranslateService,
    private apiEndpointsService: ApiEndpointsService,
    private globalNavService: GlobalNavService,
    private analytics: AnalyticsService,
    private authService: AuthService,
    private cookieService: NgxCookieService,
    private sidenavService: SidenavService,
    private navService: NavService,
    private portfolioService: PortfolioService,
    private organizationsService: OrganizationsService,
  ) {
    organizationsService.orgs$.subscribe((orgs: Context[]) => {
      this.orgList = orgs;
    });
    this.getNavUrl();
  }

  async getNavUrl() {
    this.navUrl = await this.navService.getNavUrl();
  }

  ngOnInit(): void {
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe(result => {
        document.title = result;
      });
    });

    this.portfolioService.programs$.subscribe(programs => {
      if (programs && programs.length > 0) {
        this.programs = programs;
        this.portfolioService.selectProgram(this.programs[0].id);
      }
    });
    this.portfolioService.selectedProgram$.subscribe(program => {
      if (program) {
        this.selectedProgramId = program.id;
      }
    });
    this.portfolioService.getPrograms();
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }

  selectProgram(e) {
    this.portfolioService.selectProgram(e);
    if (!this.router.isActive('/', true)) {
      this.router.navigate([`/`], {});
    }
    console.log('Selected Program:', e);
  }
}
