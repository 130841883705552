<div class="app-portfolio-create content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>library_add</mat-icon>
                </div>
                <h1 id="create-title" data-automation="pageTitle">{{ 'portfolio.create.page_title' | translate }}</h1>
                <p class="subtitle ellipsis" data-automation="pageSubtitle">
                    {{ 'portfolio.create.subtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column" data-automation="rightColumn">
            <button
                class="icon-btn x-btn-secondary"
                aria-label="Cancel"
                (click)="handleCancel()"
                data-automation="portfolioCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'portfolio.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="Submit"
                (click)="handleSubmit()"
                [disabled]="!portfolioForm.valid || !createForm.valid"
                type="submit"
                data-automation="portfolioSubmit"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'portfolio.button.submit' | translate }}</span>
            </button>
        </div>
    </header>
    <div class="page-content">
        <form #createForm="ngForm" data-app-prefix="prt" class="portfolio-form">
            <app-portfolio-form [mode]="mode" [portfolioForm]="portfolioForm"></app-portfolio-form>
        </form>
    </div>
</div>
