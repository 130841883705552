import { Directive } from '@angular/core';
import { UntypedFormGroup, NG_VALIDATORS, Validator } from '@angular/forms';
@Directive({
  selector: '[appSourceValid]',
  providers: [{ provide: NG_VALIDATORS, useExisting: SourceValidator, multi: true }],
})
export class SourceValidator implements Validator {
  validate(control: UntypedFormGroup) {
    let sourceIDs = [];
    let controlParent = control.parent.parent.controls;
    for (const key in controlParent) {
      if (key.match(/baselinePointsForm_/g) || key.match(/telemetryPointsForm_/g)) {
        for (const propertyInside in controlParent[key]['controls']) {
          if (propertyInside.match(/sourceId_/g)) {
            if (controlParent[key]['controls'][propertyInside] !== control) {
              sourceIDs.push(controlParent[key]['controls'][propertyInside].value);
            }
          }
        }
      }
    }

    var sourceIdControl = control.value?.trim();
    if (sourceIdControl != "") {
      if (sourceIDs.includes(sourceIdControl) && (control.touched || control.dirty || control.parent.parent.touched)) {
        return {
          sourceInvalid: true
        };
      } else {
        for (const key in controlParent) {
          if (key.match(/baselinePointsForm_/g) || key.match(/telemetryPointsForm_/g)) {
            for (const propertyInside in controlParent[key]['controls']) {
              if (propertyInside.match(/sourceId_/g)) {
                const duplicatedSourceIds = sourceIDs.filter(duplicatedSourceId => {
                  return duplicatedSourceId === controlParent[key]['controls'][propertyInside].value;
                });
                if (controlParent[key]['controls'][propertyInside].status == "INVALID" && duplicatedSourceIds.length === 1 && sourceIdControl !== controlParent[key]['controls'][propertyInside].value) {
                  controlParent[key]['controls'][propertyInside].setErrors(null);
                  controlParent[key]['controls'][propertyInside].markAsUntouched({
                    self: true
                  });
                  controlParent[key]['controls'][propertyInside].updateValueAndValidity();
                }
              }
            }
          }
        }

      }
    } else {
      for (const key in controlParent) {
        if (key.match(/baselinePointsForm_/g)) {
          for (const propertyInside in controlParent[key]['controls']) {
            if (propertyInside.match(/sourceId_/g)) {

              const duplicatedSourceIds = sourceIDs.filter(duplicatedSourceId => {
                return duplicatedSourceId === controlParent[key]['controls'][propertyInside].value
              });
              if (controlParent[key]['controls'][propertyInside].status == "INVALID" && duplicatedSourceIds.length === 1 && sourceIdControl !== controlParent[key]['controls'][propertyInside].value) {
                controlParent[key]['controls'][propertyInside].setErrors(null);
                controlParent[key]['controls'][propertyInside].markAsUntouched({
                  self: true
                });
                controlParent[key]['controls'][propertyInside].updateValueAndValidity();
              }

            }
          }
        }
      }
    }
    return null;
  }

}
