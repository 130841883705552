import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as moment from 'moment-timezone';

export class ValidatorFunctions {
  static dateAfterBuilder = (beforeControl: string): ValidatorFn => {
    return (control: AbstractControl) => {
      if (!control.parent) {
        return {
          noParent: true,
        };
      }

      if(!control.value){
        return null;
      }
      
      const beforeDate = moment(control.parent.get(beforeControl).value);
      const me = moment(control.value);
      if (!me.isAfter(beforeDate)) {
        return {
          dateNotAfter: true,
        };
      }
      return null;
    };
  };
}
