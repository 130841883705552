<div ngModelGroup="app-portfolio" id="portfolioForm" [formGroup]="portfolioForm">
    <div class="form-group" data-automation="portfolioForms">
        <multi-locale-input
            (onChange)="triggerValidation()"
            formControlName="displayLabels"
            [inputLabel]="nameLabel"
            [locales]="supportedLocales.value"
            [displayLabelKey]="multiLocaleConfig.displayLabelKey"
            [localeKey]="multiLocaleConfig.localeKey"
            [defaultLocale]="multiLocaleConfig.defaultLocale"
            [placeholderText]="namePlaceholder"
            [required]="true"
            [id]="'portfolio_display_labels'"
        >
        </multi-locale-input>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="portfolioId">
            <mat-label>{{ 'portfolio.external_reference_id' | translate }}</mat-label>
            <input
                id="externalReferenceId"
                matInput
                required
                maxlength="80"
                placeholder="{{ 'portfolio.create.placeholder.external_reference_id' | translate }}"
                formControlName="externalReferenceId"
                data-automation="externalReferenceId"
                data-url-persist
            />
            <mat-error *ngIf="externalReferenceId.hasError('required')">
                {{ 'portfolio.validation.required' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="supportedLocales">
            <mat-label>{{ 'portfolio.supported_locales' | translate }}</mat-label>
            <mat-select
                formControlName="supportedLocales"
                data-automation="supportedLocales"
                placeholder="{{ 'portfolio.create.placeholder.supported_locales' | translate }}"
                (selectionChange)="handleSelectionChange()"
                multiple
            >
                <mat-option
                    *ngFor="let locale of locales"
                    [value]="locale"
                    [disabled]="limitLocaleSelectList(5, locale)"
                >
                    {{ locale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <multi-locale-input
            (onChange)="triggerValidation()"
            formControlName="descriptions"
            [inputLabel]="descriptionLabel"
            [locales]="supportedLocales.value"
            [displayLabelKey]="multiLocaleConfig.displayLabelKey"
            [localeKey]="multiLocaleConfig.localeKey"
            [defaultLocale]="multiLocaleConfig.defaultLocale"
            [placeholderText]="descriptionPlaceholder"
            [id]="'portfolio_descriptions'"
        >
        </multi-locale-input>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'portfolio.timezone' | translate }}</mat-label>
            <mat-select
                required
                formControlName="timezone"
                data-automation="timezone"
                placeholder="{{ 'portfolio.create.placeholder.timezone_selector' | translate }}"
            >
                <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">
                    {{ timezone.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'portfolio.default_locale' | translate }}</mat-label>
            <mat-select
                required
                formControlName="defaultLocale"
                data-automation="locale"
                placeholder="{{ 'portfolio.create.placeholder.locale_selector' | translate }}"
            >
                <mat-option
                    *ngFor="let supportedLocale of portfolioForm.controls.supportedLocales.value"
                    [value]="supportedLocale.localeName"
                >
                    {{ supportedLocale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="form-group">
        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'portfolio.effective_from' | translate }}</mat-label>
            <input
                matInput
                required
                [matDatepicker]="effectiveFromDatepicker"
                placeholder="{{ 'portfolio.create.placeholder.effective_from' | translate }}"
                formControlName="effectiveFrom"
                data-automation="effectiveFrom"
            />
            <mat-datepicker-toggle matSuffix [for]="effectiveFromDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #effectiveFromDatepicker></mat-datepicker>
            <mat-error *ngIf="effectiveFrom.hasError('required')">
                {{ 'portfolio.validation.required' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'portfolio.effective_to' | translate }}</mat-label>
            <input
                matInput
                [min]="effectiveFrom.value"
                [matDatepicker]="effectiveToDatepicker"
                placeholder="{{ 'portfolio.create.placeholder.effective_to' | translate }}"
                formControlName="effectiveTo"
                data-automation="effectiveTo"
            />
            <mat-datepicker-toggle matSuffix [for]="effectiveToDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #effectiveToDatepicker></mat-datepicker>
        </mat-form-field>
    </div>

    <h4 class="form-header" data-automation="Market Configuration">
        {{ 'portfolio.market_configuration' | translate }}
    </h4>
    <div class="form-group" formGroupName="marketConf" data-automation="marketFields">
        <mat-form-field appearance="outline" floatLabel="always" data-automation="reserveRate">
            <mat-label>{{ 'portfolio.market_configuration.reserve_rate' | translate }}</mat-label>
            <input
                id="reserveRate"
                type="number"
                matInput
                placeholder="{{ 'portfolio.create.placeholder.market_configuration.reserve_rate' | translate }}"
                formControlName="reserveRate"
                data-url-persist
            />
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'portfolio.market_configuration.resource_type' | translate }}</mat-label>
            <mat-select
                formControlName="resourceType"
                data-automation="resourceType"
                placeholder="{{ 'portfolio.create.placeholder.market_configuration.resource_type' | translate }}"
            >
                <mat-option *ngFor="let type of resourceTypes" [value]="type.code">
                    {{ type.displayLabel | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always">
            <mat-label>{{ 'portfolio.market_configuration.ramp_rate' | translate }}</mat-label>
            <input
                id="rampRate"
                type="number"
                matInput
                placeholder="{{ 'portfolio.create.placeholder.market_configuration.ramp_rate' | translate }}"
                formControlName="rampRate"
                data-automation="rampRate"
                data-url-persist
            />
        </mat-form-field>
    </div>

    <!-- Telemetry Points -->

    <ng-container *ngIf="isCreateMode || isEditMode || hasPoints">
        <div class="telemetry-points-header form-header">
            <h2 data-automation="points-header">
                {{ 'telemetry_point.title' | translate }}
            </h2>
            <mat-icon
                data-automation="add-point"
                class="add-point"
                *ngIf="isEditMode || isCreateMode"
                svgIcon="plusButton"
                (click)="addDynamicPoint()"
            ></mat-icon>
        </div>

        <div class="points-content">
            <div class="empty-points" *ngIf="!hasPoints">
                <div><mat-icon svgIcon="noData"></mat-icon></div>
                <h6>{{ 'telemetry_point.empty_title' | translate }}</h6>
                <div data-automation="empty-points" class="empty-filler">{{ 'telemetry_point.empty' | translate }}</div>
            </div>

            <ng-container *ngIf="hasPoints">
                <ng-container *ngFor="let point of points; let i = index; let c = count">
                    <app-telemetry-point
                        *ngIf="!point.deleted"
                        [mode]="mode"
                        [point]="point"
                        [userLocale]="userLocale"
                        [portfolioName]="displayLabels.value[defaultLocale.value]"
                        [reportingIntervals]="reportingIntervals"
                        [dataProviders]="dataProviders"
                        [selectedChannels]="selectedChannels"
                        [index]="i"
                        [count]="c"
                        [multiLocaleConfig]="multiLocaleConfig"
                        [portfolio]="portfolioId"
                        (addDynamicPoint)="addDynamicPoint()"
                        (deleteDynamicPoint)="deleteDynamicPoint($event)"
                        (updateSelectedChannels)="updateSelectedChannels()"
                        (updateSourceIds)="updateSourceIds()"
                    >
                    </app-telemetry-point>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>

    <h4 class="form-header" data-automation="Registrations">
        {{ 'portfolio.registrations' | translate }}
        <mat-spinner *ngIf="loadingRegistrations" matSuffix diameter="23"></mat-spinner>
    </h4>
    <h6 class="form-subheader">
        <div>
            {{ 'portfolio.currently_selected' | translate }}:
            {{ registrations && registrations.value ? registrations.value.length : 0 }}
            <span class="missing-error" *ngIf="errors">
                {{ 'portfolio.errors' | translate }}:
                {{ errors }}
            </span>
        </div>
    </h6>
    <div class="form-group">
        <mat-selection-list id="registration-list" formControlName="registrations" data-automation="registrations">
            <mat-list-option *ngFor="let registration of registrationsList" [value]="registration.id">
                <div>
                    <ng-container [ngSwitch]="registration.status">
                        <span *ngSwitchCase="'ACTIVE'" class="status-icon status-icon-active" title="Active"></span>
                        <span *ngSwitchCase="'ON_HOLD'" class="status-icon status-icon-hold" title="On Hold"></span>
                        <span
                            *ngSwitchCase="'READY_TO_ACCEPTANCE_TEST'"
                            class="status-icon status-icon-ready"
                            title="Ready to acceptance test"
                        ></span>
                        <span
                            *ngSwitchCase="'READY_TO_ENROLL'"
                            class="status-icon status-icon-ready-enroll"
                            title="Ready to enroll"
                        ></span>
                        <span *ngSwitchCase="'RETIRED'" class="status-icon status-icon-retired" title="Retired"></span>
                        <span
                            *ngSwitchCase="'INACTIVE'"
                            class="status-icon status-icon-inactive"
                            title="Inactive"
                        ></span>
                    </ng-container>
                    <span class="reg-name ellipsis" id="registrationLabel_{{ registration.id }}">{{
                        registration.displayLabel
                    }}</span>
                    <mat-icon
                        id="alertIcon_{{ registration.id }}"
                        matTooltip="{{ 'portfolio.missing_reg_error' | translate }}"
                        class="report_problem"
                        *ngIf="registration.hasError"
                    >
                        report_problem
                    </mat-icon>
                </div>
            </mat-list-option>
        </mat-selection-list>
    </div>
</div>
