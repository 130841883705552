<app-global-alert></app-global-alert>
<global-nav [multiSelect]="false" [contextList]="orgList" [navUrl]="navUrl"></global-nav>
<div class="main-container">
    <mat-sidenav-container class="x-sidebar app-container">
        <mat-sidenav
            #sidenav
            fixedInViewport="true"
            fixedTopGap="60"
            [mode]="isLargeScreen() ? 'side' : 'over'"
            [opened]="sidenavOpen"
            class="sidenav-container"
        >
            <header class="sidenav-header">
                <div class="left-column">
                    <h1 class="page-title" data-automation="listTitle">
                        {{ 'portfolio.list.title' | translate }}
                    </h1>
                </div>

                <div class="right-column">
                    <button
                        mat-icon-button
                        color="accent"
                        aria-label="Menu"
                        class="toggle-sidenav-btn"
                        data-automation="toggle-sidenav"
                        (click)="sidenav.toggle()"
                    >
                        <mat-icon>double_arrow</mat-icon>
                    </button>
                </div>
            </header>
            <div class="program-selector">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'portfolio.program' | translate }}</mat-label>
                    <mat-select
                        class="select-programs"
                        (selectionChange)="selectProgram($event.value)"
                        [value]="selectedProgramId"
                        data-automation="programsList"
                    >
                        <mat-option *ngFor="let program of programs" [value]="program.id">
                            {{ program.displayLabel }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="sidenav-component-container">
                <app-list></app-list>
            </div>
        </mat-sidenav>

        <mat-sidenav-content>
            <div class="content-section">
                <router-outlet></router-outlet>
            </div>

            <global-footer></global-footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
