/* * * ANGULAR * * */
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { CommonModule, registerLocaleData } from '@angular/common';
import { OrganizationsService } from './shared/services/organizations.service';
/* * * 3RD PARTY * * */
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

/* * * SHARED * * */
import { GlobalFooterModule } from 'ngx-global-contact-footer';
import { GlobalNavModule } from 'ngx-global-nav';
import { MultiLocaleModule } from 'multi-locale-input';

import { NgxDeeplinkerModule } from 'ngx-deeplinker';
import { TreeSelectorModule } from 'ngx-tree-selector';
import { AnalyticsService, CookieService as NgxCookieService, NOC_ANALYTICS } from 'ngx-shared-services';
import { analytics } from 'noc-analytics';
import { CookieService } from './shared/services/cookie.service';
import { GlobalAlertService } from './shared/services/global-alert.service';
import { GlobalAlertModule } from './global_alert/global-alert.module';
import { I18nService } from './shared/services/i18n.service';

import { AuthHttpInterceptor } from './services/auth.interceptor';
import { MockHttpInterceptor } from './services/mock.interceptor';

import { SourceValidator } from './shared/directives/source-id-valid.directive';
import { SelectValidator } from './shared/directives/select-valid.directive';

/* * * COMPONENTS * * */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CreateComponent } from './create/create.component';
import { ListComponent } from './list/list.component';
import { EditComponent } from './edit/edit.component';
import { PortfolioFormComponent } from './portfolio-form/portfolio-form.component';
import { DeleteDialogComponent } from './dialogs/delete/delete-dialog.component';
import { ViewComponent } from './view/view.component';
import { DefaultComponent } from './default/default.component';
import { TelemetryPointDetailsComponent } from './details/telemetry-point/telemetry-point-details.component';

import { ApiService } from './shared/services/api.service';
import { DeeplinksService } from './shared/services/deeplinks.service';
import { LocalesService } from './shared/services/locales.service';
import { StatusesService } from './shared/services/statuses.service';
import { TimezonesService } from './shared/services/timezones.service';
import { SidenavService } from './shared/services/sidenav.service';
import { SelectedSpaceService } from './shared/services/selected-space.service';
import { NavService } from './shared/services/global-nav.service';
import { TelemetryPointService } from './shared/services/telemetry-point.service';
import { SupportingDataService } from './shared/services/supporting-data.service';
import { FormValidatorService } from './shared/services/form-validator.service';
import { ResourceTypeService } from './shared/services/resource-types.service';

/* * * MATERIAL * * */
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MatMomentDateModule,
} from '@angular/material-moment-adapter';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';

import localeIt from '@angular/common/locales/it';
import localeZh from '@angular/common/locales/zh';
import localePl from '@angular/common/locales/pl';
import localeJa from '@angular/common/locales/ja';
import localeNb from '@angular/common/locales/nb';

registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeZh, 'zh-TW');
registerLocaleData(localePl, 'pl-PL');
registerLocaleData(localeJa, 'ja-JP');
registerLocaleData(localeNb, 'nb-NO');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultComponent,
    ListComponent,
    CreateComponent,
    EditComponent,
    PortfolioFormComponent,
    DeleteDialogComponent,
    ViewComponent,
    TelemetryPointDetailsComponent,
    SourceValidator,
    SelectValidator
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    BrowserAnimationsModule,
    GlobalNavModule.forRoot({ namespace: 'ngx-global-nav.' }),
    GlobalFooterModule.forRoot({ namespace: 'ngx-global-contact-footer.' }),
    GlobalAlertModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    AppRoutingModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatInputModule,
    NgxDeeplinkerModule,
    MatProgressSpinnerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
    MatCheckboxModule,
    TreeSelectorModule,
    MatButtonModule,
    MatDialogModule,
    MatSidenavModule,
    ReactiveFormsModule,
    MatListModule,
    MatExpansionModule,
    MultiLocaleModule,
    MatTooltipModule,
  ],
  providers: [
    TranslateService,
    AnalyticsService,
    NgxCookieService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    HttpClient,
    ApiService,
    GlobalAlertService,
    I18nService,
    StatusesService,
    TimezonesService,
    LocalesService,
    DeeplinksService,
    SelectedSpaceService,
    MatDatepickerModule,
    SidenavService,
    NavService,
    ResourceTypeService,
    OrganizationsService,
    TelemetryPointService,
    SupportingDataService,
    FormValidatorService,
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: false,
        strict: true,
      },
    },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    { provide: NOC_ANALYTICS, useValue: analytics },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
