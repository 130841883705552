import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Injectable()
export class FormValidatorService {
  constructor() {}

  hasRequiredDirtyFields(frmGrp: UntypedFormGroup) {
    let formIsDirty = false;

    if (!frmGrp || (frmGrp.pristine && frmGrp.invalid)) {
      return false;
    }

    for (let controlKey in frmGrp.controls) {
      const control: any = frmGrp.get(controlKey);
      try {
        if (typeof control.controls === 'undefined') {
          if (this.isRequiredField(control)) {
            if (this.isFieldDirty(control)) {
              formIsDirty = true;
              break;
            }
          }
        } else {
          // has controls need to recursively look at controls
          if (this.hasRequiredDirtyFields(control as UntypedFormGroup)) {
            formIsDirty = true;
            break;
          }
        }
      } catch (e) {
        console.log(e);
      }
    }

    return formIsDirty;
  }

  isRequiredField(control: AbstractControl) {
    if (control.validator) {
      // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
      const validator = control.validator({} as AbstractControl);
      return validator && validator.required;
    }
    return false;
  }

  isFieldDirty(control: AbstractControl) {
    if (
      (control.dirty && control.value !== '' && control.value !== null && control.value !== '-1') ||
      control.invalid
    ) {
      return true;
    }
    return false;
  }

  triggerValidation(frmGroup: UntypedFormGroup) {
    let pointsArr: any[] = [];
    for (const formName in frmGroup?.controls) {
      if (formName.match(/^baselinePointsForm_(\d)*$/)) {
        pointsArr.push(formName.match(/^baselinePointsForm_(\d)*$/));
        for (const ctrlValue in frmGroup.controls[formName].value) {
          if (
            pointsArr.length === 1 &&
            ctrlValue &&
              (ctrlValue.match(/^channel_(\d)*$/) ||
                ctrlValue.match(/^sourceId_(\d)*$/) ||
                ctrlValue.match(/^reportingInterval_(\d)*$/))
          ) {
            frmGroup.controls[formName].markAsUntouched({
              onlySelf: true,
            });
          }
          if (
            pointsArr.length >= 1 &&
            ctrlValue &&
              (ctrlValue.match(/^channel_(\d)*$/) ||
                ctrlValue.match(/^sourceId_(\d)*$/) ||
                ctrlValue.match(/^reportingInterval_(\d)*$/))
          ) {
            if (frmGroup.controls[formName].value[ctrlValue]) {
              frmGroup.controls[formName].markAllAsTouched();
              frmGroup.controls[formName].markAsDirty();
            }
          }
        }
      }
    }
  }

  clearFormControlErrors(frmGroup: UntypedFormGroup) {
    Object.keys(frmGroup.controls).forEach(key => {
      const control: any = frmGroup.get(key);
      if (typeof control.controls === 'undefined') {
        control.setErrors(null);
        control.markAsUntouched({ onlySelf: true });
      } else {
        this.clearFormControlErrors(control);
      }
    });
  }
}
